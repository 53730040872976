import React from 'react';
import MatrixRain from '../MatrixRain';
import Door from './Door';

function DoorWithMatrixRain() {
  return (
    <div style={{ position: 'relative' }}>
      <MatrixRain className="matrixRainBackground"/>
      <div style={{ marginTop: '7%' }}>
        <Door />
      </div>
    </div>
  );
}

export default DoorWithMatrixRain;