import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

const steps = ['認識䒳芮', '相信䒳芮', '交給䒳芮'];

export default function Steper() {
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());

  const isStepOptional = (step) => {
    return step === 0 || step === 1 || step === 2;
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  return (
    <Box sx={{ width: '100%', backgroundColor: 'transparent', color: 'white', p: 2 }}>
      <Stepper activeStep={activeStep}>
        {steps.map((label, index) => {
          const stepProps = {};
          const labelProps = {};
          if (isStepOptional(index)) {
            switch(index) 
            {
                case 0 :
                    labelProps.optional = (
                        <Typography variant="caption" color="white">
                          Learn more about us
                        </Typography>
                      );
                    break;
                case 1 :
                    labelProps.optional = (
                        <Typography variant="caption" color="white">
                          Trust us completely
                        </Typography>
                      );
                    break;
                case 2 :
                    labelProps.optional = (
                        <Typography variant="caption" color="white">
                            Leave the task to us with confidence
                        </Typography>
                      );
                    break;
                default :
                    break;
            }

          }
          if (isStepSkipped(index)) {
            stepProps.completed = false;
          }
          return (
            <Step key={label} {...stepProps} sx={{color:'white'}}>
              <StepLabel {...labelProps}>
                <span style={{color:'white',fontWeight:'bold'}}>
                    {label}
                </span>
              </StepLabel>
            </Step>
          );
        })}
      </Stepper>
      {activeStep === 2 ? (
        <React.Fragment>
          <Typography sx={{ mt: 2, mb: 1,marginLeft: 5, marginRight: 5, color: 'white',fontWeight:'bold' }}>
            恭喜您距離夢想只差一點點了<br/> 
            請聯繫我們 讓我們成為您夢想路上最堅強的後盾
          </Typography>
          <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
            <Box sx={{ flex: '1 1 auto' }} />
            <Button onClick={handleReset} variant="outlined" color="inherit">
              Reset
            </Button>
          </Box>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <Typography 
            sx={{ mt: 2, mb: 1, marginLeft: 5, marginRight: 5,fontWeight:'bold' }}
            >
            {activeStep === 0 ? 
                '䒳芮是由一群集匠心獨具的前後端工程師和創意無限的UI/UX設計師於一體的專業團隊。我們的成員都是業界的資深人才，擁有豐富多年的專業經驗，致力於為客戶提供卓越且獨具特色的數位解決方案。在䒳芮，我們深刻理解每一個項目都是獨一無二的，因此我們以精湛的技藝和創新的思維，投入到每一個細節之中，確保為您量身定制最適合的解決方案。我們的目標不僅僅是滿足客戶的需求，更是致力於超越客戶的期望，帶來前所未有的業務增長和品牌價值提升。' : 
                (activeStep === 1 ? 
                '與一般的接案公司相比，䒳芮工作室以更具競爭力的價格，提供更高層次的品質服務。我們深信，優質的服務並不一定需要昂貴的代價。在這裡，您將體驗到一種全新的合作模式，我們的專業團隊會與您攜手共進，共同打造令人驚艷的數位體驗。選擇䒳芮工作室，即是選擇一個專業、創新、且充滿熱情的夥伴。讓我們攜手共創美好未來，將您的願景轉化為現實。' : 
                ''
                )
            }
          </Typography>
          <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
            <Button
              color="inherit"
              disabled={activeStep === 0}
              onClick={handleBack}
              sx={{ mr: 1 }}
              variant="outlined"
            >
              Back
            </Button>
            <Box sx={{ flex: '1 1 auto' }} />
            {isStepOptional(activeStep) && (
              <Button color="inherit" onClick={handleSkip} sx={{ mr: 1 }} variant="outlined">
                Skip
              </Button>
            )}

            <Button onClick={handleNext} variant="outlined" style={{color:'white',borderColor:'white'}}>
              {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
            </Button>
          </Box>
        </React.Fragment>
      )}
    </Box>
  );
}
