import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import { CardActionArea, CardActions } from '@mui/material';
import { Box, Typography, Fade, useTheme } from '@mui/material';

function Slider() {
  
    return (
        <>
            <DemoCard title={'客製化系統'} imgrul={'/system.png'}
            detail={'網站革命，為您帶來無比新體驗。我們善於將創意轉化為實際作品，挑戰傳統、追求卓越。從定製系統到設計，為品牌加分，致力提供卓越的用戶體驗'}/>
            <Box sx={{ mx: 2 }}></Box> {/* This provides space between the cards */}
            <DemoCard title={'電商網站設計'} imgrul={'/shopping.png'}
            detail={'電商網站是品牌的數位門面。我們致力於打造獨特的購物旅程。每次點擊，都是一場冒險。我們注重用戶體驗，簡化流程，讓購物變得輕鬆又愉快'}/>
            <Box sx={{ mx: 2 }}></Box>
            <DemoCard title={'專注於產品'} imgrul={'/product.png'}
            detail={'Dorey推出了多款產品，其中最受企業喜愛的是Dorey.Chrs。透過線上打卡，自動化自算薪資，幫助企業人資減少工作負擔，提升工作效率和產值'}/>
        </>
    );
  }

export default Slider;

function DemoCard({title,detail,imgrul}) {
    return (
        <Card sx={{ maxWidth: 345, backgroundColor: 'black', borderRadius: '16px', border: '2px solid gray' }}>
          <CardActionArea>
            <CardMedia
              component="img"
              height="auto"
              image={imgrul}
              alt="green iguana"
            />
            <CardContent>
              <Typography gutterBottom variant="h5" component="div" style={{fontWeight:'bold',color:'white'}}>
                {title}
              </Typography>
              <Typography variant="body2" color="text.secondary" style={{color:'white',marginBottom:'10%'}}>
                {detail}
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
    );
}
