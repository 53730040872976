import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { CardActionArea, Grid } from '@mui/material';
import { Typography } from '@mui/material';

function SliderPh() {
    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <DemoCard title={'客製化系統'} imgrul={'/system.png'}
                detail={'網站革命，為您帶來無比新體驗。我們善於將創意轉化為實際作品，挑戰傳統、追求卓越。從定製系統到設計，為品牌加分，致力提供卓越的用戶體驗'}/>
            </Grid>
            <Grid item xs={12}>
                <DemoCard title={'電商網站設計'} imgrul={'/shopping.png'}
                detail={'電商網站是品牌的數位門面。我們致力於打造獨特的購物旅程。每次點擊，都是一場冒險。我們注重用戶體驗，簡化流程，讓購物變得輕鬆又愉快'}/>
            </Grid>
            <Grid item xs={12}>
                <DemoCard title={'專注於產品'} imgrul={'/product.png'}
                detail={'Dorey推出了多款產品，其中最受企業喜愛的是Dorey.CHRS'}/>
            </Grid>
        </Grid>
    );
}

function DemoCard({title, detail, imgrul}) {
    return (
        <Card sx={{ maxWidth: '80%', backgroundColor: 'black', borderRadius: '16px', border: '2px solid gray',margin:'auto' }}>
            <CardActionArea>
                <CardMedia
                    component="img"
                    height="auto"
                    image={imgrul}
                    alt={title}
                />
                <CardContent>
                    <Typography gutterBottom variant="h5" component="div" style={{fontWeight:'bold',color:'white'}}>
                        {title}
                    </Typography>
                    <Typography variant="body2" style={{color:'white',marginBottom:'10%'}}>
                        {detail}
                    </Typography>
                </CardContent>
            </CardActionArea>
        </Card>
    );
}

export default SliderPh;
