import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import { CardActionArea, CardActions } from '@mui/material';
import { Box, Typography, Fade, useTheme } from '@mui/material';
import { styled } from '@mui/system';

const ImageBox = styled(Box)(({ theme }) => ({
  width: '1920px',
  height: '800px',
  backgroundImage: 'url("/aboutbk.jpg")', // 請替換為你的照片URL
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  position: 'relative',
  overflow: 'hidden',
  boxShadow: theme.shadows[7],
  display: 'flex', 
  justifyContent: 'center', 
  alignItems: 'center',
}));

function AboutBanner() {
    return (
      <Fade in={true} timeout={1500}>
        <ImageBox>
        <Box 
          display="flex" 
          flexDirection="column" // 新增：設定方向為垂直
          alignItems="center" // 新增：水平居中
          justifyContent="center" // 新增：垂直居中
        >
          <Typography 
            variant="h2" 
            component="div" 
            color="white" 
            style={{fontWeight: 'bold', marginBottom: '16px' ,textShadow: '6px 6px 6px rgba(0, 0, 0, 0.5)'}} // 新增：增加下邊距
          >
            About Us
          </Typography>
          <Typography 
            variant="h5" 
            component="div" 
            color="white" 
            style={{fontWeight: 'bold',marginTop:'5%',textShadow: '6px 6px 6px rgba(0, 0, 0, 1)'}}
          >
            䒳芮自成立以來，我們始終秉持著創新、熱情與專業的精神，致力於提供卓越的產品和服務。<br/>
            在這裡，我們相信科技的力量能夠改變世界，而我們的任務就是成為這股改變的推動者。<br/>
            䒳芮是一個多元化的程式廚房，集結了來自不同領域的大廚，共同追求卓越與創新。<br/>
            我們的產品線涵蓋了許多面向，旨在為我們的客戶提供度身訂製且一流的解決方案。<br/>
            在 䒳芮，我們不僅僅是一間工作室，更是一個追求卓越並富含人情味的團隊。我們聆聽客戶的需求，並用心去理解和實現。<br/>
            每一個項目，每一個產品，都是我們對品質和創新承諾的見證。加入我們的旅程，一同探索無限的可能性。          
          </Typography>
        </Box>
        </ImageBox>
      </Fade>
    );
  }

export default AboutBanner;

