import React, { useState, useEffect ,useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import '../../animate.css/animate.css'
import axios from 'axios';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Card from '@mui/material/Card';
import Alert from '@mui/material/Alert';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import IconButton from '@mui/material/IconButton';
import LoginIcon from '@mui/icons-material/Login';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { useCallback } from "react";
import { loadSlim } from "tsparticles-slim"; 
import Particles from "react-particles";
import NavbarPh from './NavbarPh';
import BannerPh from './BannerPh';
import SliderPh from './SliderPh';


export default function HomePh() {
  const navigate = useNavigate();
 

  return (
    <>
        <Box sx={{ flexGrow: 1,width:'100%',overflow:'auto'}}>
            <Grid container spacing={0}>
                <Grid item xs={12}>
                    <NavbarPh/>
                </Grid>
                <Grid item xs={12} style={{justifyContent:'center',display:'flex'}}>
                    <ThemeProvider theme={createTheme()}>
                        <BannerPh/>
                    </ThemeProvider>
                </Grid>
                <Grid item xs={12} style={{justifyContent:'center',display:'flex',marginTop:'5%'}}>
                    <SliderPh/>
                </Grid>
                <Grid item xs={12} style={{justifyContent:'center',display:'flex',marginTop:'5%'}} className='animate__animated animate__fadeIn'>
                    <Typography variant="h5" gutterBottom style={{fontWeight:'bold',color:'white'}}>
                        為何要選擇䒳芮
                    </Typography>
                </Grid>
                <BackAnimate/>
                <Grid item xs={12} style={{marginTop:'2%'}}>
                    <Typography variant="subtitle1" gutterBottom style={{justifyContent: 'center', display: 'flex', marginTop: '2%',fontWeight:'bold' ,color:'white',width:'80%',margin:'auto'}}>
                        專業的系統規劃、資料庫設計<br/>
                        讓您高枕無憂 
                    </Typography>
                </Grid>
                <Grid item xs={12} style={{marginTop:'2%'}}>
                    <Typography variant="subtitle1" gutterBottom style={{justifyContent: 'center', display: 'flex', marginTop: '2%',fontWeight:'bold',color:'white',width:'80%',margin:'auto'}}>
                        我們擁有多年的專案管理經驗<br/>
                        可以在短時間內瞭解您的需求
                    </Typography>
                </Grid>
                <Grid item xs={12} style={{marginTop:'10%'}}>
                    <Typography variant="subtitle1" gutterBottom style={{justifyContent: 'center', display: 'flex', marginTop: '2%',fontWeight:'bold',color:'white',width:'80%',margin:'auto'}}>
                        讓我們一起解決您多年來痛處<br/>
                        不要再委屈自己
                    </Typography>
                </Grid>
                <Grid item xs={12} style={{marginTop:'2%'}}>
                    <Typography variant="subtitle1" gutterBottom style={{justifyContent: 'center', display: 'flex', marginTop: '2%',fontWeight:'bold',color:'white',width:'80%',margin:'auto'}}>
                    打造頂尖的系統客製解決方案<br/>
                    全面滿足您的業務需求 
                    </Typography>
                </Grid>
                <Grid item xs={12} style={{marginTop:'2%'}}>
                    <Typography variant="subtitle1" gutterBottom style={{justifyContent: 'center', display: 'flex', marginTop: '2%',fontWeight:'bold',color:'white',width:'80%',margin:'auto'}}>
                    整合創新技術為企業帶來卓越
                    </Typography>
                </Grid>
               
                <Grid item xs={12} style={{marginTop:'10%'}}>
                    <Typography variant="subtitle1" gutterBottom style={{justifyContent: 'center', display: 'flex', marginTop: '10%',fontWeight:'bold',color:'white',width:'80%',margin:'auto'}}>
                    於䒳芮而言，程式碼猶如料理一般<br/>
                    每個專案，是我們對食材深情解讀<br/>
                    由資深後端掌廚、前端上色、UI擺盤<br/>
                    最盛具靈魂的料理，就由䒳芮呈上
                    </Typography>
                </Grid>
            </Grid>
        </Box>
    </>
  );
}


function BackAnimate() {
    const particlesInit = useCallback(async engine => {
        console.log(engine);
        // you can initiate the tsParticles instance (engine) here, adding custom shapes or presets
        // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
        // starting from v2 you can add only the features you need reducing the bundle size
        //await loadFull(engine);
        await loadSlim(engine);
        }, []);
    
        const particlesLoaded = useCallback(async container => {
            await console.log(container);
        }, []);

    return (
        <div className="particles-container">
        <Particles
            id="tsparticles"
            init={particlesInit}
            loaded={particlesLoaded}
            options={{
                background: {
                    color: {
                        value: "#000000",
                    },
                },
                fpsLimit: 120,
                interactivity: {
                    events: {
                        onClick: {
                            enable: true,
                            mode: "push",
                        },
                        onHover: {
                            enable: true,
                            mode: "repulse",
                        },
                        resize: true,
                    },
                    modes: {
                        push: {
                            quantity: 4,
                        },
                        repulse: {
                            distance: 200,
                            duration: 0.4,
                        },
                    },
                },
                particles: {
                    color: {
                        value: "#ffffff",
                    },
                    links: {
                        color: "#ffffff",
                        distance: 150,
                        enable: true,
                        opacity: 0.5,
                        width: 1,
                    },
                    move: {
                        direction: "none",
                        enable: true,
                        outModes: {
                            default: "bounce",
                        },
                        random: false,
                        speed: 2,
                        straight: false,
                    },
                    number: {
                        density: {
                            enable: true,
                            area: 800,
                        },
                        value: 60,
                    },
                    opacity: {
                        value: 0.5,
                    },
                    shape: {
                        type: "circle",
                    },
                    size: {
                        value: { min: 1, max: 5 },
                    },
                },
                detectRetina: true,
            }}
        /> 
      </div>
    );
}
