import React, { useState, useEffect ,useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import '../../animate.css/animate.css'
import axios from 'axios';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Card from '@mui/material/Card';
import Alert from '@mui/material/Alert';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import IconButton from '@mui/material/IconButton';
import { CardActionArea } from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { useCallback } from "react";
import Particles from "react-particles";
//import { loadFull } from "tsparticles"; // if you are going to use `loadFull`, install the "tsparticles" package too.
import { loadSlim } from "tsparticles-slim"; // if you are going to use `loadSlim`, install the "tsparticles-slim" package too.
import NavbarPh from '../homePh/NavbarPh';
import ServiceBannerPh from './ServiceBannerPh';


export default function ServicePh() {
  const navigate = useNavigate();
 
 
  return (
    <>
        <Box sx={{ flexGrow: 1,width:'100%'}}>
            <Grid container spacing={0}>
                <Grid item xs={12}>
                    <NavbarPh/>
                </Grid>
                <Grid item xs={12} style={{justifyContent:'center',display:'flex'}}>
                    <ThemeProvider theme={createTheme()}>
                        <ServiceBannerPh/>
                    </ThemeProvider>
                </Grid>
                <Grid item xs={12} style={{justifyContent:'center',display:'flex',marginTop:'5%'}} className='animate__animated animate__fadeIn'>
                    <Typography variant="h3" gutterBottom style={{fontWeight:'bold',color:'white'}}>
                        服務項目 
                    </Typography>
                </Grid>
                <BackAnimate/>
                <Grid item xs={12} style={{ justifyContent: 'center', display: 'flex', marginTop: '2%' }}>
                    <ServiceCard img={'/shop.png'} name={'RWD電商網站'}/>
                </Grid>
                <Grid item xs={12} style={{ marginTop: '2%', display: 'flex', alignItems: 'center', height: '250px',marginLeft:'10%',marginRight:'10%'  }}>
                    <Typography variant="body1" style={{ color: 'white',fontWeight: 'bold' }}>
                    打破實體商店的限制，我們的RWD電商網站為您提供無縫的購物體驗<br/>
                    從產品展示到付款流程，我們著重細節與用戶友善，為您創造極致的購物樂趣<br/>
                    無論任何裝置、任何場合，您的商店都將便利於手，銷售無處不在
                    </Typography>
                </Grid>   
                <Grid item xs={12} style={{ justifyContent: 'center', display: 'flex', marginTop: '2%' }}>
                    <ServiceCard img={'/linebot.png'} name={'Line Bot'}/>
                </Grid>            
                <Grid item xs={12} style={{ marginTop: '2%', display: 'flex', alignItems: 'center', height: '250px',marginLeft:'10%',marginRight:'10%'  }}>
                    <Typography variant="body1" style={{ color: 'white', fontWeight: 'bold'}}>
                    Line Bot，您的智慧助手，隨時隨地滿足您的需求<br/>
                    䒳芮打造的Line Bot能即時回應、提供資訊、訂單下訂、並優化用戶體驗<br/>
                    從客服到推銷，我們的技術確保您的服務不間斷，永遠跟上時代的步伐，讓您睡夢中都能培養顧客忠誠度
                    </Typography>
                </Grid>

                <Grid item xs={12} style={{ justifyContent: 'center', display: 'flex', marginTop: '2%' }}>
                    <ServiceCard img={'/atendtion.png'} name={'企業形象網站'}/>
                </Grid>
                <Grid item xs={12} style={{ marginTop: '2%', display: 'flex', alignItems: 'center', height: '250px',marginLeft:'10%',marginRight:'10%'  }}>
                    <Typography variant="body1" style={{ color: 'white',fontWeight: 'bold' }}>
                    創建一個專屬的企業形象網站，是品牌建立與訊息傳遞的關鍵<br/>
                    䒳芮為您量身打造獨特、專業且具有吸引力的網站，讓您的企業在競爭激烈的市場中脫穎而出<br/>
                    從設計、功能到用戶體驗，我們全方位呈現您的品牌價值，贏得客戶的信任與青睞
                    </Typography>
                </Grid>   
                <Grid item xs={12} style={{ justifyContent: 'center', display: 'flex', marginTop: '2%' }}>
                    <ServiceCard img={'/comsystem.png'} name={'客製化系統'}/>
                </Grid>
                <Grid item xs={12} style={{ marginTop: '2%', display: 'flex', alignItems: 'center', height: '250px',marginLeft:'10%',marginRight:'10%'  }}>
                    <Typography variant="body1" style={{ color: 'white', fontWeight: 'bold' }}>
                    有效的內部管理系統是企業效率與生產力的關鍵<br/>
                    䒳芮深入了解您的業務需求，為您打造客製化的內部系統，從資料整合、流程自動化到工作效能優化，我們一一照顧<br/>
                    我們的專業團隊確保系統的穩定性、安全性與可擴展性，助您的企業更靈活、更高效，輕鬆應對未來的挑戰
                    </Typography>
                </Grid>
                <Grid item xs={12} style={{ justifyContent: 'center', display: 'flex', marginTop: '2%' }}>
                    <ServiceCard img={'/server.png'} name={'伺服器架設'}/>
                </Grid>
                <Grid item xs={12} style={{ marginTop: '5%', display: 'flex', alignItems: 'center', height: '250px',marginLeft:'10%',marginRight:'10%'  }}>
                    <Typography variant="body1" style={{ color: 'white',fontWeight: 'bold' }}>
                    在網路世界中，穩定與高效的伺服器是成功的基石<br/>
                    䒳芮具備專業的技術和豐富的經驗，提供從規劃、架設到維護的一站式伺服器解決方案我們深刻理解您的業務需求<br/>
                    量身打造合適的伺服器環境，確保您的網站和應用程式始終運行於最佳狀態<br/>
                    不論規模大小，䒳芮都能夠為您提供靈活、可靠的伺服器架設服務，讓您無後顧之憂，專注於業務發展
                    </Typography>
                </Grid>   
                <Grid item xs={12} style={{ justifyContent: 'center', display: 'flex', marginTop: '2%' }}>
                    <ServiceCard img={'/api.png'} name={'Api開發串接'}/>
                </Grid>
                <Grid item xs={12} style={{ marginTop: '5%', display: 'flex', alignItems: 'center', height: '250px',marginLeft:'10%',marginRight:'10%'  }}>
                    <Typography variant="body1" style={{ color: 'white', fontWeight: 'bold'}}>
                    數據交流的流暢與即時性，是現代應用程式的靈魂<br/>
                    䒳芮的大廚團隊精通API開發與串接，致力於打造無縫、高效的數據互動<br/>
                    並以創新的技術解決方案，確保數據流動無礙、溝通無界<br/>
                    從第三方支付、社交媒體連接到各種外部資源的整合<br/>
                    讓您的產品達到前所未有的聯動性和靈活性
                    </Typography>
                </Grid>
                <Grid item xs={12} style={{ justifyContent: 'center', display: 'flex', marginTop: '2%' }}>
                    <ServiceCard img={'/uiux.png'} name={'UIUX規劃設計'}/>
                </Grid>
                <Grid item xs={12} style={{ marginTop: '5%', display: 'flex', alignItems: 'center', height: '250px',marginLeft:'10%',marginRight:'10%'  }}>
                    <Typography variant="body1" style={{ color: 'white',fontWeight: 'bold' }}>
                    在創造一個數位產品時，用戶的體驗是至關重要的<br/>
                    䒳芮的設計團隊致力於提供一流的UIUX規劃設計<br/>
                    從用戶的視角出發，打造直觀、流暢且引人入勝的界面和體驗<br/>
                    我們的目標是讓每一個細節都能為您的品牌增添價值<br/>
                    並確保用戶在使用過程中的每一刻都充滿滿足感和愉悅䒳芮將藝術與技術完美融合，為您創造卓越的用戶體驗
                    </Typography>
                </Grid>   
            </Grid>
        </Box>
    </>
  );
}


function BackAnimate() {
    const particlesInit = useCallback(async engine => {
        console.log(engine);
        // you can initiate the tsParticles instance (engine) here, adding custom shapes or presets
        // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
        // starting from v2 you can add only the features you need reducing the bundle size
        //await loadFull(engine);
        await loadSlim(engine);
        }, []);
    
        const particlesLoaded = useCallback(async container => {
            await console.log(container);
        }, []);

    return (
        <div className="particles-container">
        <Particles
            id="tsparticles"
            init={particlesInit}
            loaded={particlesLoaded}
            options={{
                background: {
                    color: {
                        value: "#000000",
                    },
                },
                fpsLimit: 120,
                interactivity: {
                    events: {
                        onClick: {
                            enable: true,
                            mode: "push",
                        },
                        onHover: {
                            enable: true,
                            mode: "repulse",
                        },
                        resize: true,
                    },
                    modes: {
                        push: {
                            quantity: 4,
                        },
                        repulse: {
                            distance: 200,
                            duration: 0.4,
                        },
                    },
                },
                particles: {
                    color: {
                        value: "#ffffff",
                    },
                    links: {
                        color: "#ffffff",
                        distance: 150,
                        enable: true,
                        opacity: 0.5,
                        width: 1,
                    },
                    move: {
                        direction: "none",
                        enable: true,
                        outModes: {
                            default: "bounce",
                        },
                        random: false,
                        speed: 2,
                        straight: false,
                    },
                    number: {
                        density: {
                            enable: true,
                            area: 800,
                        },
                        value: 60,
                    },
                    opacity: {
                        value: 0.5,
                    },
                    shape: {
                        type: "circle",
                    },
                    size: {
                        value: { min: 1, max: 5 },
                    },
                },
                detectRetina: true,
            }}
        /> 
      </div>
    );
}
function ServiceCard({img,name}) {
    return (
        <Card sx={{ maxWidth: 345, borderRadius: '16px'  }}>
        <CardActionArea>
            <CardMedia
            component="div"
            sx={{
                height: '200px', 
                width:'200px',// 你可以設定想要的高度
                backgroundImage: `url(${img})`,
                backgroundSize: 'cover', // 確保圖片覆蓋整個區域
                backgroundPosition: 'center', // 圖片居中
                backgroundRepeat: 'no-repeat', // 避免圖片重複
                backgroundColor:'white'
            }}
            alt="green iguana"
            />
            <CardContent>
            <Typography gutterBottom variant="h5" component="div" style={{fontWeight:'bold',display: 'flex', alignItems: 'center',justifyContent:'center'}}>
                {name}
            </Typography>
            </CardContent>
        </CardActionArea>
        </Card>
    );
  }