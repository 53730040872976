import React, { useState, useEffect ,useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import '../animate.css/animate.css'
import axios from 'axios';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Card from '@mui/material/Card';
import Alert from '@mui/material/Alert';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import IconButton from '@mui/material/IconButton';
import LoginIcon from '@mui/icons-material/Login';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { useCallback } from "react";
import Particles from "react-particles";
//import { loadFull } from "tsparticles"; // if you are going to use `loadFull`, install the "tsparticles" package too.
import { loadSlim } from "tsparticles-slim"; // if you are going to use `loadSlim`, install the "tsparticles-slim" package too.
import Navbar from './Navbar';
import Banner from './Banner';
import Steper from './Steper';
import Slider from './Slider';


export default function Home() {
  const navigate = useNavigate();
 

  return (
    <>
        <Box sx={{ flexGrow: 1,width:'100%',overflow:'auto',minWidth:'900px'}}>
            <Grid container spacing={0}>
                <Grid item xs={12}>
                    <Navbar/>
                </Grid>
                <Grid item xs={12} style={{justifyContent:'center',display:'flex'}}>
                    <ThemeProvider theme={createTheme()}>
                        <Banner/>
                    </ThemeProvider>
                </Grid>
                <Grid item xs={12} style={{justifyContent:'center',display:'flex',marginTop:'5%'}}>
                    <Slider/>
                </Grid>
                <Grid item xs={12} style={{justifyContent:'center',display:'flex',marginTop:'5%'}} className='animate__animated animate__fadeIn'>
                    <Typography variant="h3" gutterBottom style={{fontWeight:'bold',color:'white'}}>
                        為何要選擇䒳芮 
                    </Typography>
                </Grid>
                <BackAnimate/>
                <Grid item xs={4} style={{justifyContent: 'center', display: 'flex', marginTop: '2%'}}/>
                <Grid item xs={8} style={{marginTop:'2%'}}>
                    <Typography variant="h6" gutterBottom style={{fontWeight:'bold' ,color:'white'}}>
                        專業的系統規劃、資料庫設計，讓您高枕無憂 
                    </Typography>
                    <Typography variant="h6" gutterBottom style={{fontWeight:'bold',marginLeft:'5%' ,color:'white'}}>
                        我們擁有多年的專案管理經驗，可以在短時間內瞭解您的需求
                    </Typography>
                    <Typography variant="h6" gutterBottom style={{fontWeight:'bold',marginLeft:'10%' ,color:'white'}}>
                        讓我們一起解決您多年來的痛處，不要再委屈自己
                    </Typography>
                </Grid>
                <Grid item xs={2} style={{justifyContent: 'center', display: 'flex', marginTop: '2%'}}/>
                <Grid item xs={8} style={{marginTop:'2%'}}>
                    <Typography variant="h6" gutterBottom style={{fontWeight:'bold' ,color:'white'}}>
                            打造頂尖的系統解決方案，全面滿足您的業務需求 
                    </Typography>
                    <Typography variant="h6" gutterBottom style={{fontWeight:'bold',marginLeft:'5%' ,color:'white'}}>
                            整合創新技術，為您的業務帶來卓越與效率
                    </Typography>
                    <Typography variant="h6" gutterBottom style={{fontWeight:'bold',marginLeft:'10%' ,color:'white'}}>
                            緊密合作，精心規劃，讓我們一起為您的系統未來打下堅實基礎
                    </Typography>
                </Grid>
                <Grid item xs={2} style={{justifyContent: 'center', display: 'flex', marginTop: '2%'}}/>
                <Grid item xs={5} style={{justifyContent: 'center', display: 'flex', marginTop: '2%'}}/>
                <Grid item xs={7} style={{marginTop:'2%'}}>
                    <Typography variant="h6" gutterBottom style={{fontWeight:'bold' ,color:'white'}}>
                        由專業的UIUX為您設計精緻的用餐體驗 
                    </Typography>
                    <Typography variant="h6" gutterBottom style={{fontWeight:'bold',marginLeft:'5%' ,color:'white'}}>
                        佐以資深的大廚用嚴謹工序烹飪，食材挑選
                    </Typography>
                    <Typography variant="h6" gutterBottom style={{fontWeight:'bold',marginLeft:'10%' ,color:'white'}}>
                        最後由靈性的設計師淋上密製調料，三者合一<br/>
                        讓程式廚房為您舉辦饕餮盛宴，用廚藝征服您的舌尖
                    </Typography>
                </Grid>
                <Grid item xs={12} style={{justifyContent:'center',display:'flex',marginTop:'5%'}}>
                    <Steper/>
                </Grid>
            </Grid>
        </Box>
    </>
  );
}


function BackAnimate() {
    const particlesInit = useCallback(async engine => {
        console.log(engine);
        // you can initiate the tsParticles instance (engine) here, adding custom shapes or presets
        // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
        // starting from v2 you can add only the features you need reducing the bundle size
        //await loadFull(engine);
        await loadSlim(engine);
        }, []);
    
        const particlesLoaded = useCallback(async container => {
            await console.log(container);
        }, []);

    return (
        <div className="particles-container">
        <Particles
            id="tsparticles"
            init={particlesInit}
            loaded={particlesLoaded}
            options={{
                background: {
                    color: {
                        value: "#000000",
                    },
                },
                fpsLimit: 120,
                interactivity: {
                    events: {
                        onClick: {
                            enable: true,
                            mode: "push",
                        },
                        onHover: {
                            enable: true,
                            mode: "repulse",
                        },
                        resize: true,
                    },
                    modes: {
                        push: {
                            quantity: 4,
                        },
                        repulse: {
                            distance: 200,
                            duration: 0.4,
                        },
                    },
                },
                particles: {
                    color: {
                        value: "#ffffff",
                    },
                    links: {
                        color: "#ffffff",
                        distance: 150,
                        enable: true,
                        opacity: 0.5,
                        width: 1,
                    },
                    move: {
                        direction: "none",
                        enable: true,
                        outModes: {
                            default: "bounce",
                        },
                        random: false,
                        speed: 2,
                        straight: false,
                    },
                    number: {
                        density: {
                            enable: true,
                            area: 800,
                        },
                        value: 60,
                    },
                    opacity: {
                        value: 0.5,
                    },
                    shape: {
                        type: "circle",
                    },
                    size: {
                        value: { min: 1, max: 5 },
                    },
                },
                detectRetina: true,
            }}
        /> 
      </div>
    );
}
