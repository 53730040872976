import React from 'react';
import Starry from 'react-starry-sky';

export default function Sky() {
    return (
        <div className="starry-background">
            <Starry className="starry-background"/>
        </div>
    );
}
