import React, { useState, useEffect ,useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import '../animate.css/animate.css'
import axios from 'axios';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { CardActionArea } from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { useCallback } from "react";
import Particles from "react-particles";
//import { loadFull } from "tsparticles"; // if you are going to use `loadFull`, install the "tsparticles" package too.
import { loadSlim } from "tsparticles-slim"; // if you are going to use `loadSlim`, install the "tsparticles-slim" package too.
import Navbar from '../home/Navbar';
import ContactBanner from './ContactBanner';


export default function Contact() {

  return (
    <>
        <Box sx={{ flexGrow: 1,width:'100%',overflow:'auto',minWidth:'900px'}}>
            <Grid container spacing={0}>
                <Grid item xs={12}>
                    <Navbar/>
                </Grid>
                <Grid item xs={12} style={{justifyContent:'center',display:'flex'}}>
                    <ThemeProvider theme={createTheme()}>
                        <ContactBanner/>
                    </ThemeProvider>
                </Grid>
                <Grid item xs={12} style={{justifyContent:'center',display:'flex',marginTop:'5%'}} className='animate__animated animate__fadeIn'>
                    <Typography variant="h3" gutterBottom style={{fontWeight:'bold',color:'white'}}>
                        聯絡資訊
                    </Typography>
                </Grid>
                <BackAnimate/>
                <Grid item xs={12} style={{ justifyContent: 'center', display: 'flex', marginTop: '2%'}}>
                    <QRCard img={'/qr.jpg'} name={'DoRey Studio'} detail={'程式廚房'}/>
                </Grid>
                <Grid item xs={12} style={{justifyContent:'center',display:'flex',marginTop:'5%'}} className='animate__animated animate__fadeIn'>
                    <Typography variant="subtitle2" gutterBottom style={{fontWeight:'bold',color:'white'}}>
                        放心把菜單交給我們，讓大廚們為您獻上靈魂菜餚。
                    </Typography>
                </Grid>
            </Grid>
        </Box>
    </>
  );
}


function BackAnimate() {
    const particlesInit = useCallback(async engine => {
        console.log(engine);
        // you can initiate the tsParticles instance (engine) here, adding custom shapes or presets
        // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
        // starting from v2 you can add only the features you need reducing the bundle size
        //await loadFull(engine);
        await loadSlim(engine);
        }, []);
    
        const particlesLoaded = useCallback(async container => {
            await console.log(container);
        }, []);

    return (
        <div className="particles-container">
        <Particles
            id="tsparticles"
            init={particlesInit}
            loaded={particlesLoaded}
            options={{
                background: {
                    color: {
                        value: "#000000",
                    },
                },
                fpsLimit: 120,
                interactivity: {
                    events: {
                        onClick: {
                            enable: true,
                            mode: "push",
                        },
                        onHover: {
                            enable: true,
                            mode: "repulse",
                        },
                        resize: true,
                    },
                    modes: {
                        push: {
                            quantity: 4,
                        },
                        repulse: {
                            distance: 200,
                            duration: 0.4,
                        },
                    },
                },
                particles: {
                    color: {
                        value: "#ffffff",
                    },
                    links: {
                        color: "#ffffff",
                        distance: 150,
                        enable: true,
                        opacity: 0.5,
                        width: 1,
                    },
                    move: {
                        direction: "none",
                        enable: true,
                        outModes: {
                            default: "bounce",
                        },
                        random: false,
                        speed: 2,
                        straight: false,
                    },
                    number: {
                        density: {
                            enable: true,
                            area: 800,
                        },
                        value: 60,
                    },
                    opacity: {
                        value: 0.5,
                    },
                    shape: {
                        type: "circle",
                    },
                    size: {
                        value: { min: 1, max: 5 },
                    },
                },
                detectRetina: true,
            }}
        /> 
      </div>
    );
}

function QRCard({img,name,detail}) {
    return (
        <Card sx={{ maxWidth: 345 }}>
        <CardActionArea>
            <CardMedia
            component="div"
            sx={{
                height: '200px', 
                width:'200px',// 你可以設定想要的高度
                backgroundImage: `url(${img})`,
                backgroundSize: 'cover', // 確保圖片覆蓋整個區域
                backgroundPosition: 'center', // 圖片居中
                backgroundRepeat: 'no-repeat', // 避免圖片重複
                backgroundColor:'black'
            }}
            alt="green iguana"
            />
            <CardContent>
            <Typography gutterBottom variant="h5" component="div"style={{fontWeight:'bold',justifyContent:'center',display:'flex'}}>
                {name}
            </Typography>
            <Typography variant="body2" color="text.secondary" style={{fontWeight:'bold',justifyContent:'center',display:'flex'}}>
                {detail}
            </Typography>
            </CardContent>
        </CardActionArea>
        </Card>
    );
  }
