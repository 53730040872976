import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import { CardActionArea, CardActions } from '@mui/material';
import { Box, Typography, Fade, useTheme } from '@mui/material';
import { styled } from '@mui/system';

const ImageBox = styled(Box)(({ theme }) => ({
  width: '100%',
  height: 'auto',
  backgroundImage: 'url("/ph/aboutbk_ph.jpg")', // 請替換為你的照片URL
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  position: 'relative',
  overflow: 'hidden',
  boxShadow: theme.shadows[7],
  display: 'flex', 
  justifyContent: 'center', 
  alignItems: 'center',
}));

function AboutBannerPh() {
    return (
      <Fade in={true} timeout={1500}>
        <ImageBox>
        <Box 
          display="flex" 
          flexDirection="column" // 新增：設定方向為垂直
          alignItems="center" // 新增：水平居中
          justifyContent="center" // 新增：垂直居中
        >
          <Typography 
            variant="h4" 
            component="div" 
            color="white" 
            style={{fontWeight: 'bold', marginBottom: '16px' ,textShadow: '6px 6px 6px rgba(0, 0, 0, 0.5)',display:'flex',justifyContent:'center'
            ,alignItems:"center"}} // 新增：增加下邊距
          >
            About Us
          </Typography>
          <Typography 
            variant="h6" 
            component="div" 
            color="white" 
            style={{fontWeight: 'bold',marginTop:'10%',textShadow: '6px 6px 6px rgba(0, 0, 0, 1)',marginBottom:'5%'}}
          >
            䒳芮是一個多元化的程式廚房<br/>
            集結了來自不同領域的大廚<br/>
            共同追求卓越與創新。<br/>
            我們的菜品涵蓋了許多面向<br/>
            旨在為我們的客戶提供不同風味的盛宴。<br/>         
          </Typography>
        </Box>
        </ImageBox>
      </Fade>
    );
  }

export default AboutBannerPh;

