import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import { CardActionArea, CardActions } from '@mui/material';
import { Box, Typography, Fade, useTheme } from '@mui/material';
import { styled } from '@mui/system';

const ImageBox = styled(Box)(({ theme }) => ({
  width: '1920px',
  height: '800px',
  backgroundImage: 'url("/servicebk.jpg")', // 請替換為你的照片URL
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  position: 'relative',
  overflow: 'hidden',
  boxShadow: theme.shadows[7],
  display: 'flex', 
  justifyContent: 'center', 
  alignItems: 'center',
}));

function ServiceBanner() {
    return (
      <Fade in={true} timeout={1500}>
        <ImageBox>
        <Box 
          display="flex" 
          flexDirection="column" // 新增：設定方向為垂直
          alignItems="center" // 新增：水平居中
          justifyContent="center" // 新增：垂直居中
        >
          <Typography 
            variant="h2" 
            component="div" 
            color="white" 
            style={{fontWeight: 'bold', marginBottom: '16px' ,textShadow: '6px 6px 6px rgba(0, 0, 0, 0.5)'}} // 新增：增加下邊距
          >
            What kind of service can we provide for you?
          </Typography>
          <Typography 
            variant="h5" 
            component="div" 
            color="white" 
            style={{fontWeight: 'bold',marginTop:'10%',textShadow: '6px 6px 6px rgba(0, 0, 0, 1)'}}
          >
            䒳芮的團隊成員遍佈四海，我們深信：單獨前行或許迅速，但群體同行則能走得更遠。<br/>
            我們各自守護著自己的專業領域，同時在不同的專案中互相支持與協作。<br/>
            我們堅定的認為，程式碼猶如一道道精緻的美食，而我們便是那些致力於為每家企業和個人烹飪出最符合他們口味的大廚。<br/>
            在烹調的過程中，我們不僅注重菜品的擺盤藝術，更講究食材的新鮮度和營養均衡，只為呈現能夠挑逗您味蕾的佳餚。<br/>
            我們不自譽為業界的頂尖大廚，但我們自信能成為您解決問題的最佳夥伴。
          </Typography>
        </Box>
        </ImageBox>
      </Fade>
    );
  }

export default ServiceBanner;

