import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import { CardActionArea, CardActions } from '@mui/material';
import { Box, Typography, Fade, useTheme } from '@mui/material';
import { styled } from '@mui/system';

const ImageBox = styled(Box)(({ theme }) => ({
  width: '100%',
  height: 'auto',
  backgroundImage: 'url("/ph/contactbk_ph.jpg")', // 請替換為你的照片URL
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  position: 'relative',
  overflow: 'hidden',
  boxShadow: theme.shadows[7],
  display: 'flex', 
  justifyContent: 'center', 
  alignItems: 'center',
}));

function ContactBannerPh() {
    return (
      <Fade in={true} timeout={1500}>
        <ImageBox>
        <Box 
          display="flex" 
          flexDirection="column" // 新增：設定方向為垂直
          alignItems="center" // 新增：水平居中
          justifyContent="center" // 新增：垂直居中
        >
          <Typography 
            variant="h4" 
            component="div" 
            color="white" 
            style={{fontWeight: 'bold', marginBottom: '16px' ,textShadow: '6px 6px 6px rgba(0, 0, 0, 0.5)',display:'flex',justifyContent:'center'
            ,alignItems:"center"}} 
          >
            Contact Us
          </Typography>
          <Typography 
            variant="h6" 
            component="div" 
            color="white" 
            style={{fontWeight: 'bold',marginTop:'10%',textShadow: '6px 6px 6px rgba(0, 0, 0, 1)',marginBottom:'5%'}}
          >
               只要地球尚未毀滅的一天<br/>
               䒳芮便不會有歇息的一天<br/>
               歡迎透過Qrcode加入我們好友<br/>
               與我們聊聊您的夢想     
          </Typography>
        </Box>
        </ImageBox>
      </Fade>
    );
  }

export default ContactBannerPh;

