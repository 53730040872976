import React, { useState, useEffect ,useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import '../animate.css/animate.css'
import axios from 'axios';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { CardActionArea } from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { useCallback } from "react";
import Particles from "react-particles";
//import { loadFull } from "tsparticles"; // if you are going to use `loadFull`, install the "tsparticles" package too.
import { loadSlim } from "tsparticles-slim"; // if you are going to use `loadSlim`, install the "tsparticles-slim" package too.
import Navbar from '../home/Navbar';
import AboutBanner from './AboutBanner';


export default function About() {

  return (
    <>
        <Box sx={{ flexGrow: 1,width:'100%',overflow:'auto',minWidth:'900px'}}>
            <Grid container spacing={0}>
                <Grid item xs={12}>
                    <Navbar/>
                </Grid>
                <Grid item xs={12} style={{justifyContent:'center',display:'flex'}}>
                    <ThemeProvider theme={createTheme()}>
                        <AboutBanner/>
                    </ThemeProvider>
                </Grid>
                <Grid item xs={12} style={{justifyContent:'center',display:'flex',marginTop:'5%'}} className='animate__animated animate__fadeIn'>
                    <Typography variant="h3" gutterBottom style={{fontWeight:'bold',color:'white'}}>
                        團隊成員
                    </Typography>
                </Grid>
                <BackAnimate/>

                <Grid item xs={8} style={{ marginTop: '2%', display: 'flex', alignItems: 'center', height: '250px', justifyContent: 'flex-end'  }}>
                    <Typography variant="body1" style={{ color: 'white',fontWeight: 'bold', textAlign: 'right'  }}>
                        20幾年從業經驗，面對過各式各樣跨領域的專案<br/>
                        Cojad哥總是徜徉在程式碼的浩瀚裡，專業的知識蘊含在其標誌性的落腮鬍裡<br/>          
                    </Typography>
                </Grid>
                <Grid item xs={2} style={{ justifyContent: 'center', display: 'flex', marginTop: '2%' }}>
                    <MemberCard img={'/cojad.png'} name={'Cojad'} detail={'CTO 技術長'}/>
                </Grid>
                <Grid item xs={2}/>

                <Grid item xs={2}/>
                <Grid item xs={2} style={{ justifyContent: 'center', display: 'flex', marginTop: '2%' }}>
                    <MemberCard img={'/ahow.png'} name={'Ahow'} detail={'BackEnd 後端工程師'}/>
                </Grid>
                <Grid item xs={8} style={{ marginTop: '2%', display: 'flex', alignItems: 'center', height: '250px' }}>
                    <Typography variant="body1" style={{ color: 'white',fontWeight: 'bold' }}>
                        在一個數位產品的背後，後端工程師扮演著不可或缺的角色。<br/>
                        Ahow專注於建立、維護、和優化伺服器、數據庫和應用程式的核心功能，確保前端的美觀介面有著強大且穩定的基礎。<br/>
                        面對客戶給予的各種挑戰，Ahow總是將獠牙對準那些等待在前方的巨石，用毅力與決心，將勝利的果實與團隊共享。
                    </Typography>
                </Grid>              
                <Grid item xs={8} style={{ marginTop: '2%', display: 'flex', alignItems: 'center', height: '250px', justifyContent: 'flex-end'  }}>
                    <Typography variant="body1" style={{ color: 'white',fontWeight: 'bold', textAlign: 'right'  }}>
                        充滿熱情的Ryan<br/>
                        我曾問過他，為甚麼喜歡寫程式?<br/>
                        他回我一句:寫程式比顧小孩輕鬆多了
                    </Typography>
                </Grid>
                <Grid item xs={2} style={{ justifyContent: 'center', display: 'flex', marginTop: '2%' }}>
                    <MemberCard img={'/ryan.png'} name={'Ryan'} detail={'FullStack 全端工程師'}/>
                </Grid>
                <Grid item xs={2}/>

                <Grid item xs={2}/>
                <Grid item xs={2} style={{ justifyContent: 'center', display: 'flex', marginTop: '2%' }}>
                    <MemberCard img={'/amber.png'} name={'Amber'} detail={'UI Designer 靈魂雕塑者'}/>
                </Grid>
                <Grid item xs={8} style={{ marginTop: '2%', display: 'flex', alignItems: 'center', height: '250px' }}>
                    <Typography variant="body1" style={{ color: 'white',fontWeight: 'bold' }}>
                        平面設計的靈魂人物<br/>
                        靈感源源不絕，是Amber的招牌，總能想出客戶滿意的logo與介面<br/>
                        鬼靈精怪的她，將我們的菜品撒上獨特的香料。
                    </Typography>
                </Grid>  
            </Grid>
        </Box>
    </>
  );
}


function BackAnimate() {
    const particlesInit = useCallback(async engine => {
        console.log(engine);
        // you can initiate the tsParticles instance (engine) here, adding custom shapes or presets
        // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
        // starting from v2 you can add only the features you need reducing the bundle size
        //await loadFull(engine);
        await loadSlim(engine);
        }, []);
    
        const particlesLoaded = useCallback(async container => {
            await console.log(container);
        }, []);

    return (
        <div className="particles-container">
        <Particles
            id="tsparticles"
            init={particlesInit}
            loaded={particlesLoaded}
            options={{
                background: {
                    color: {
                        value: "#000000",
                    },
                },
                fpsLimit: 120,
                interactivity: {
                    events: {
                        onClick: {
                            enable: true,
                            mode: "push",
                        },
                        onHover: {
                            enable: true,
                            mode: "repulse",
                        },
                        resize: true,
                    },
                    modes: {
                        push: {
                            quantity: 4,
                        },
                        repulse: {
                            distance: 200,
                            duration: 0.4,
                        },
                    },
                },
                particles: {
                    color: {
                        value: "#ffffff",
                    },
                    links: {
                        color: "#ffffff",
                        distance: 150,
                        enable: true,
                        opacity: 0.5,
                        width: 1,
                    },
                    move: {
                        direction: "none",
                        enable: true,
                        outModes: {
                            default: "bounce",
                        },
                        random: false,
                        speed: 2,
                        straight: false,
                    },
                    number: {
                        density: {
                            enable: true,
                            area: 800,
                        },
                        value: 60,
                    },
                    opacity: {
                        value: 0.5,
                    },
                    shape: {
                        type: "circle",
                    },
                    size: {
                        value: { min: 1, max: 5 },
                    },
                },
                detectRetina: true,
            }}
        /> 
      </div>
    );
}

function MemberCard({img,name,detail}) {
    return (
        <Card sx={{ maxWidth: 345 }}>
        <CardActionArea>
            <CardMedia
            component="div"
            sx={{
                height: '200px', 
                width:'200px',// 你可以設定想要的高度
                backgroundImage: `url(${img})`,
                backgroundSize: 'cover', // 確保圖片覆蓋整個區域
                backgroundPosition: 'center', // 圖片居中
                backgroundRepeat: 'no-repeat', // 避免圖片重複
                backgroundColor:'black'
            }}
            alt="green iguana"
            />
            <CardContent>
            <Typography gutterBottom variant="h5" component="div">
                {name}
            </Typography>
            <Typography variant="body2" color="text.secondary" style={{fontWeight:'bold'}}>
                {detail}
            </Typography>
            </CardContent>
        </CardActionArea>
        </Card>
    );
  }
