import React, { useState, useEffect ,useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import '../animate.css/animate.css'
import axios from 'axios';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { CardActionArea } from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { useCallback } from "react";
import Particles from "react-particles";
//import { loadFull } from "tsparticles"; // if you are going to use `loadFull`, install the "tsparticles" package too.
import { loadSlim } from "tsparticles-slim"; // if you are going to use `loadSlim`, install the "tsparticles-slim" package too.
import Navbar from '../home/Navbar';


export default function Product() {

  return (
    <>
        <Box sx={{ flexGrow: 1,width:'100%',overflow:'auto',minWidth:'900px'}}>
            <Grid container spacing={0}>
                <Grid item xs={12}>
                    <Navbar/>
                </Grid>
                <Grid item xs={12} style={{justifyContent:'center',display:'flex',marginTop:'5%'}} className='animate__animated animate__fadeIn'>
                    <Typography variant="h3" gutterBottom style={{fontWeight:'bold',color:'white'}}>
                        Dorey CHRS 線上人資雲
                    </Typography>
                </Grid>
                <BackAnimate/>
                <Grid item xs={1}/>
                <Grid item xs={3} style={{ justifyContent: 'center', display: 'flex', marginTop: '2%' }}>
                    <img src='/doreychrs1.png' alt='線上人資雲' style={{ width: '300%', height: '100%', objectFit: 'contain' }}/>
                </Grid>
                <Grid item xs={6} style={{  display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                    <Typography variant="body1" style={{ color: 'white', fontWeight: 'bold' }}>
                        在這個數位時代，人資管理不再是紙筆的繁瑣流程。Dorey帶您翱翔於雲端，用智慧打造未來的企業文化。<br/>
                        Dorey的線上人資雲產品，背負著中小企業轉型的使命。透過手機一鍵打卡，簡單、便捷，將您的人資管理推向新的高峰<br/>                                         
                    </Typography>
                </Grid>
                <Grid item xs={2}/>


                <Grid item xs={2}/>
                <Grid item xs={3} style={{ justifyContent: 'center', display: 'flex' }}>
                    <img src='/doreychrs2.png' alt='線上人資雲' style={{ width: '300%', height: '100%', objectFit: 'contain' }}/>
                </Grid>
                <Grid item xs={6} style={{  display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                    <Typography variant="body1" style={{ color: 'white', fontWeight: 'bold' }}>
                    考慮到員工的各項需求，Dorey將您日常所需要的功能，整合於一處<br/>
                    從打卡、請假到績效管理，我們提供您一站式的人資解決方案<br/>
                    Dorey深思熟慮，針對員工的需求，提供全面的日常功能。不論是出勤管理或員工福利，皆能在我們的平台上輕鬆完成                                                            
                    </Typography>
                </Grid>
                <Grid item xs={1}/>

                <Grid item xs={1}/>
                <Grid item xs={3} style={{ justifyContent: 'center', display: 'flex'}}>
                    <img src='/doreychrs4.png' alt='線上人資雲' style={{ width: '300%', height: '100%', objectFit: 'contain' }}/>
                </Grid>
                <Grid item xs={6} style={{  display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                    <Typography variant="body1" style={{ color: 'white', fontWeight: 'bold' }}>
                        簡潔的介面，讓您的員工可以無縫轉換打卡模式<br/>
                        人性化的設置，讓導入CHRS如魚得水，而我們的專員，也會親臨現場輔導企業導入該平台<br/>                                         
                    </Typography>
                </Grid>
                <Grid item xs={2}/>

                <Grid item xs={12} style={{  display: 'flex', alignItems: 'center', justifyContent: 'center',marginTop:'5%'}}>
                    <Typography variant="body1" style={{ color: 'white', fontWeight: 'bold' }}>
                    🌟 讓薪資計算變得輕鬆無負擔！ 🌟<br/>                      
                    想像一下，透過一個平台，您可以在瞬間計算員工的薪水、精準審核每筆薪資、輕鬆掌握出勤狀況，一切僅需手指輕點，就能完成！<br/>
                    💼 業主的最佳助手：<br/>
                    快速計算薪水✔️：不再需要複雜的計算和手動輸入，讓我們的平台為您自動、準確地計算每位員工的薪資。<br/>
                    輕鬆審核薪資✔️：透過直覺的界面，您可以快速審核並確保每筆薪資的正確無誤。<br/>
                    全面了解出勤狀況✔️：即時追踪員工的出勤、請假、遲到等狀況，讓您毫不費力地管理員工的工時。<br/>
                    功能豐富，一應俱全✔️：我們的平台提供各種多樣的功能，滿足您所有的企業需求。<br/>
                    日益精進✔️：持續改版與優化中，業主若有任何建議，我們都會在下次改版列入優化清單。<br/>
                    讓我們協助您節省100%的時間和精力，讓薪資管理變得前所未有的簡單。立即加入我們，體驗真正的便利！🚀<br/>  
                    <br/>
                    <br/>
                    體驗方式✈️: 歡迎透過Qrcode加入我們好友，預約體驗Dorey.Chrs線上人資雲 <br/>  
                    員工數5人以下免費使用💡<br/>          
                    員工數5人以上免費使用三個月🎉<br/>  
                    定價方案💰: 每位員工 99台幣/每月，年繳享9折優惠，員工數大於30人請私訊，有額外優惠可以享用<br/>     
                    <br/>
                    <br/>
                    功能涵蓋 👏:一鍵計算薪資，薪水報表統計，出缺勤管理，審核假別管理，會議安排，輪班制、日薪制、PartTime制、固定班別，各行各業皆適用<br/> 
                    䒳芮線上人資雲系統，等您來體驗。                         
                    </Typography>
                </Grid>
            </Grid>
        </Box>
    </>
  );
}


function BackAnimate() {
    const particlesInit = useCallback(async engine => {
        console.log(engine);
        // you can initiate the tsParticles instance (engine) here, adding custom shapes or presets
        // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
        // starting from v2 you can add only the features you need reducing the bundle size
        //await loadFull(engine);
        await loadSlim(engine);
        }, []);
    
        const particlesLoaded = useCallback(async container => {
            await console.log(container);
        }, []);

    return (
        <div className="particles-container">
        <Particles
            id="tsparticles"
            init={particlesInit}
            loaded={particlesLoaded}
            options={{
                background: {
                    color: {
                        value: "#000000",
                    },
                },
                fpsLimit: 120,
                interactivity: {
                    events: {
                        onClick: {
                            enable: true,
                            mode: "push",
                        },
                        onHover: {
                            enable: true,
                            mode: "repulse",
                        },
                        resize: true,
                    },
                    modes: {
                        push: {
                            quantity: 4,
                        },
                        repulse: {
                            distance: 200,
                            duration: 0.4,
                        },
                    },
                },
                particles: {
                    color: {
                        value: "#ffffff",
                    },
                    links: {
                        color: "#ffffff",
                        distance: 150,
                        enable: true,
                        opacity: 0.5,
                        width: 1,
                    },
                    move: {
                        direction: "none",
                        enable: true,
                        outModes: {
                            default: "bounce",
                        },
                        random: false,
                        speed: 2,
                        straight: false,
                    },
                    number: {
                        density: {
                            enable: true,
                            area: 800,
                        },
                        value: 60,
                    },
                    opacity: {
                        value: 0.5,
                    },
                    shape: {
                        type: "circle",
                    },
                    size: {
                        value: { min: 1, max: 5 },
                    },
                },
                detectRetina: true,
            }}
        /> 
      </div>
    );
}


