import React from 'react';
import MatrixRain from '../../MatrixRain';
import DoorPh from './DoorPh';

function DoorWithMatrixRainPh() {
  return (
    <div style={{ position: 'relative' }}>
      <MatrixRain className="matrixRainBackground"/>
      <div style={{
        display: 'flex',          // 启用 Flexbox 布局
        justifyContent: 'center', // 水平居中
        alignItems: 'center',     // 垂直居中
        height: '100vh',          // 父容器高度
        marginTop: '5%'
      }}>
        <DoorPh />
      </div>
    </div>
  );
}
export default DoorWithMatrixRainPh;