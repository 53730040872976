import React, { useState, useEffect ,useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import '../../animate.css/animate.css'
import axios from 'axios';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Card from '@mui/material/Card';
import Alert from '@mui/material/Alert';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import IconButton from '@mui/material/IconButton';
import LoginIcon from '@mui/icons-material/Login';


export default function DoorPh() {
  const navigate = useNavigate();
  const [isVisible, setIsVisible] = useState(false);

  const handleInsideDorey = () => {
      navigate('/home', { replace: true }); 
  };
  
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(true);
    }, 1500);  

    return () => clearTimeout(timer);
  }, []); 

  if (!isVisible) {
    return null; 
  }

  return (
    <Box sx={{ flexGrow: 1,width:'80%',margin:'auto',backgroundColor:'black',zIndex:0}} className="animate__animated animate__fadeIn">
      <Grid container spacing={2}>
        <Grid item xs={12} style={{color:'white',fontSize:'20px',padding: '0px 20px',wordWrap: 'break-word',textAlign: 'center',margin:'5%'}}>
            Dorey Technology Studio.
        </Grid>
        <Grid item xs={12} style={{color:'white',padding: '0px 20px',wordWrap: 'break-word',textAlign: 'center'}}>
          <IconButton aria-label="delete" onClick={handleInsideDorey}>
            <LoginIcon style={{color:'white', fontSize: '60px' }} />
          </IconButton>
        </Grid>
        <Grid item xs={12} style={{color:'white',padding: '0px 20px',wordWrap: 'break-word',textAlign: 'center',margin:'5%'}}>
            <Typography variant="h6" gutterBottom>
              Let us create endless possibilities for you.
            </Typography>
        </Grid>
      </Grid>
    </Box>
  );
}
