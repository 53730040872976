import React from 'react';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Navbar from '../home/Navbar';
import Grid from '@mui/material/Grid';
import LinearProgress from '@mui/material/LinearProgress';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Sky from './Sky';

export default function Case() {
  const gradientStyle = {
    background: '-webkit-linear-gradient(45deg, pink, deepskyblue)',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
  };
  return (
    <>
        <Sky />
        <div style={{ zIndex: 1, position: 'relative' }}>
        <Box sx={{ flexGrow: 1,width:'100%',overflow:'auto',minWidth:'900px',backgroundColor:'transparent'}}>      
            <Grid container spacing={0} style={{backgroundColor:'transparent'}}>
                <Grid item xs={12}>
                    <Navbar/>
                </Grid>      
                <Grid item xs={2}/>  
                <Grid item xs={8} style={{justifyContent:'center',display:'flex',marginTop:'5%',marginBottom:'5%'}}>
                    <ImageList variant="masonry" cols={3} gap={20} style={{width: 'auto', height: 'auto',overflow:'hidden',padding:'16px'}}>
                        {itemData.map((item) => (
                        <ImageListItem key={item.img} className="image-item">
                            <img
                            srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
                            src={`${item.img}?w=248&fit=crop&auto=format`}
                            alt={item.title}
                            loading="lazy"
                            style={{ objectFit: 'cover', width: '100%', height: '100%' }}
                            />
                        </ImageListItem>
                        ))}
                    </ImageList>
                </Grid>
                <Grid item xs={2}/>

                <Grid item xs={1}/>
                <Grid item xs={2} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <Stack sx={{ width: '100%', color: 'grey.500' }} spacing={2}>
                    <LinearProgress color="secondary" />
                  </Stack>
                </Grid>
                <Grid item xs={5} style={{ display: 'flex', alignItems: 'center' }}>
                  <Typography variant="h5" gutterBottom style={{ fontWeight: 'bold', marginLeft: '5%', ...gradientStyle }}>
                    ShowCase One
                  </Typography>
                </Grid>
                <Grid item xs={4}/>

                <Grid item xs={1}/>
                <Grid item xs={2} style={{ display: 'flex', alignItems: 'center' }}>
                  <Typography variant="h5" gutterBottom style={{ fontWeight: 'bold', marginLeft: '5%',color:'white' }}>
                    華辰企業股份有限公司
                  </Typography>
                </Grid>
                <Grid item xs={5} style={{ display: 'flex', alignItems: 'center' }}>
                  <Typography variant="subtitle1" gutterBottom style={{ fontWeight: 'bold',color:'white' }}>
                    客製化ERP系統
                  </Typography>
                </Grid>
                <Grid item xs={4}/>       
                <Grid item xs={1}/>
                <Grid item xs={4} style={{ display: 'flex', alignItems: 'center' }}>
                  <Typography variant="subtitle2" gutterBottom style={{ fontWeight: 'bold', marginLeft: '5%',color:'white' }}>
                  隨著時光流轉，20多年前所開發的ERP系統已逐漸顯示出其局限性。面對日益增長的業務需求和伺服器技術的老化，公司深刻認識到系統升級的迫切性。在時間壓力之下，䒳芮勇敢接下這份充滿挑戰的任務。我們的工程師團隊多次前往客戶端，反覆確認需求細節，通過團隊的通力合作，我們不僅成功遷移了客戶的舊有資料庫，還在令人難以置信的短時間內完成了系統的升級。這一成就不僅超出了客戶的預期，也使得企業在新系統的支持下，持續在業界展現出卓越的光芒與活力。
                  </Typography>
                </Grid>
                <Grid item xs={7}/>    

                <Grid item xs={6}/>
                <Grid item xs={2} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <Stack sx={{ width: '100%', color: 'grey.500' }} spacing={2}>
                    <LinearProgress color="success" />
                  </Stack>
                </Grid>
                <Grid item xs={4} style={{ display: 'flex', alignItems: 'center' }}>
                  <Typography variant="h5" gutterBottom style={{ fontWeight: 'bold', marginLeft: '5%', ...gradientStyle }}>
                    ShowCase Two
                  </Typography>
                </Grid>

                <Grid item xs={6}/>
                <Grid item xs={2} style={{ display: 'flex', alignItems: 'center' }}>
                  <Typography variant="h5" gutterBottom style={{ fontWeight: 'bold', marginLeft: '5%',color:'white' }}>
                    歐美多益學苑
                  </Typography>
                </Grid>
                <Grid item xs={3} style={{ display: 'flex', alignItems: 'center' }}>
                  <Typography variant="subtitle1" gutterBottom style={{ fontWeight: 'bold',color:'white' }}>
                    客製化學員系統與遊戲介面
                  </Typography>
                </Grid>

                <Grid item xs={6}/>
                <Grid item xs={4} style={{ display: 'flex', alignItems: 'center' }}>
                  <Typography variant="subtitle2" gutterBottom style={{ fontWeight: 'bold',color:'white',marginLeft:'5%' }}>
                    系統起源於10多年前製作的PHP系統畫面已不符合現代審美，加上過去的技術與架構皆較為不純熟，導置網頁系統時常卡頓，
                    學員們在學生介面使用上叫苦連天，於是在網路上找到了我們，了解了需求後，便日以繼夜與業主討論畫面與系統需求，
                    不僅翻新了過去的系統架構，並擴充了許多更方便的批次功能，讓資料建檔更加敏捷，而學生介面上也費盡苦心，不僅重新設計多益學苑的闖關系統，並成功打造該補習班獨有的聽力遊戲，讓學生在遊戲中學習，也在學習中遊戲。
                  </Typography>
                </Grid>
                <Grid item xs={2}/>

                <Grid item xs={2}/>
                <Grid item xs={2} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <Stack sx={{ width: '100%', color: 'grey.500' }} spacing={2}>
                    <LinearProgress color="inherit" />
                  </Stack>
                </Grid>
                <Grid item xs={5} style={{ display: 'flex', alignItems: 'center' }}>
                  <Typography variant="h5" gutterBottom style={{ fontWeight: 'bold', marginLeft: '5%', ...gradientStyle }}>
                    ShowCase Three
                  </Typography>
                </Grid>
                <Grid item xs={3}/>

                <Grid item xs={2}/>
                <Grid item xs={2} style={{ display: 'flex', alignItems: 'center' }}>
                  <Typography variant="h5" gutterBottom style={{ fontWeight: 'bold', marginLeft: '5%',color:'white' }}>
                    金鋁光鋁業股份有限公司
                  </Typography>
                </Grid>
                <Grid item xs={8} style={{ display: 'flex', alignItems: 'center' }}>
                  <Typography variant="subtitle1" gutterBottom style={{ fontWeight: 'bold',color:'white' }}>
                    Dorey CHRS 線上人資雲系統
                  </Typography>
                </Grid>

                <Grid item xs={2}/>
                <Grid item xs={4} style={{ display: 'flex', alignItems: 'center' }}>
                  <Typography variant="subtitle2" gutterBottom style={{ fontWeight: 'bold',color:'white',marginLeft:'5%' }}>
                    坐落在台中將近30年的鋁業工程巨頭，從小小的5人逐漸壯大到至今的50幾人規模，然而員工人數的與日俱增，讓原本的算帳打卡核薪的工作負擔逐漸成為重擔，
                    加上勞基法的朝夕令改，讓人資會計們苦不堪言。<br/>
                    偶然的機會下，透過同行介紹，認識到了我們，並在董事長大力的推廣下，傳產逐漸走向電子化，不僅公司制度更加完善，人資每月的工作效率提升至少30%，
                    此案例也是傳統產業轉型的重大成就，也讓䒳芮的線上人資雲系統首次嶄露頭角。
                  </Typography>
                </Grid>
                <Grid item xs={6}/>
            </Grid>
        </Box>
        </div>
    </>
  );
}

const itemData = [
  {
    img: '/case/tutor.jpg',
    title: 'DoreyCase',
  },
  {
    img: '/case/tutorgame.jpg',
    title: 'DoreyCase',
  },
  {
    img: '/case/tutorgame2.jpg',
    title: 'DoreyCase',
  },
  {
    img: '/case/erps.jpg',
    title: 'DoreyCase',
  },
  {
    img: '/case/chrs.jpg',
    title: 'DoreyCase',
  },
  {
    img: '/case/hope.jpg',
    title: 'DoreyCase',
  },
  {
    img: '/case/hope2.jpg',
    title: 'DoreyCase',
  },
];
