import React, { useEffect,useState } from 'react';
import { BrowserRouter as Router, Route, Routes,useLocation,Link  } from 'react-router-dom';
// @mui
import { styled, Box, Typography, IconButton } from '@mui/material';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import './App.css';
import DoorWithMatrixRain from './door/DoorWithMatrixRain';
import Service from './service/Service';
import Home from './home/Home';
import Case from './case/Case';
import About from './about/About';
import Contact from './contact/Contact';
import Product from './product/Product';

import HomePh from './phone/homePh/HomePh';
import ServicePh from './phone/servicePh/ServicePh';
import AboutPh from './phone/aboutPh/AboutPh';
import ContactPh from './phone/contactPh/ContactPh';
import ProductPh from './phone/productPh/ProductPh';
import CasePh from './phone/casePh/CasePh';
import DoorWithMatrixRainPh from './phone/doorPh/DoorWithMatrixRainPh';

const Footer = styled('div')(({ theme }) => ({
  backgroundColor: 'black',
  color: 'white',
  padding: theme.spacing(3),
  borderTop: '1px solid white',
  marginTop:'10%'
}));

const AppContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  minHeight: '100vh',
});

const ContentContainer = styled('div')({
  flex: 1,
});

const FooterColumn = styled('div')({
  marginBottom: '0',
});

function ScrollToTop() {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return null;
}

function App() {

  return (
    <AppContainer>
      <Router>
        <ContentContainer>
          <Routes>
            <Route path="/" element={<ResponsiveDoor />} />
            <Route path="/home" element={<ResponsiveHome />} />
            <Route path="/case" element={<ResponsiveCase />} />
            <Route path="/service" element={<ResponsiveService />} />
            <Route path="/about" element={<ResponsiveAbout />} />
            <Route path="/contact" element={<ResponsiveContact />} />
            <Route path="/product" element={<ResponsiveProduct />} />
          </Routes>
          <ScrollToTop />
        </ContentContainer>
        <AppFooter />
      </Router>
    </AppContainer>
  );
}

function AppFooter() {
  const location = useLocation();

  // 不在 "/" 路徑下才顯示 Footer 10F., No. 65,
  if (location.pathname === '/') {
    return null;
  }

  return (
    <Footer>
        <Box display="flex" justifyContent="space-between" flexWrap="wrap">
          <FooterColumn>
            <Typography variant="h6" gutterBottom>DoRey Technology Studio</Typography> 
            <Typography variant="body2">Ln. 332, Sec. 2, Zhongshan Rd., Zhonghe Dist., New Taipei City 235 , Taiwan</Typography>
            <Typography variant="body2">新北市中和區中山路二段332巷</Typography>
            <Typography variant="body2">Email: dorei.marketing.studio@gmail.com</Typography>
            <Typography variant="body2">Phone: 0901-225-799</Typography>
          </FooterColumn>

          <FooterColumn>
            <Typography variant="h6" gutterBottom>Quick Links</Typography>
            <Link to="/about" style={{ color: 'inherit', display: 'block', marginBottom: '8px', textDecoration: 'none' }}>About Us</Link>
            <Link to="/service" style={{ color: 'inherit', display: 'block', marginBottom: '8px', textDecoration: 'none' }}>Services</Link>
            <Link to="/contact" style={{ color: 'inherit', display: 'block', marginBottom: '8px', textDecoration: 'none' }}>Contact</Link>
          </FooterColumn>
        </Box>
        <Typography variant="body2" align="center" marginTop="16px">© 2023 DoRey Technology Studio. All Rights Reserved.</Typography>
      </Footer>
  );
}

function useWindowSize() {
  const [size, setSize] = useState([0, 0]);

  useEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);

  return size;
}

function ResponsiveHome() {
  const [width] = useWindowSize();

  return width > 600 ? <Home /> : <HomePh />;
}

function ResponsiveService() {
  const [width] = useWindowSize();

  return width > 600 ? <Service /> : <ServicePh />;
}

function ResponsiveCase() {
  const [width] = useWindowSize();

  return width > 600 ? <Case /> : <CasePh />;
}

function ResponsiveAbout() {
  const [width] = useWindowSize();

  return width > 600 ? <About /> : <AboutPh />;
}

function ResponsiveContact() {
  const [width] = useWindowSize();

  return width > 600 ? <Contact /> : <ContactPh />;
}

function ResponsiveProduct() {
  const [width] = useWindowSize();

  return width > 600 ? <Product /> : <ProductPh />;
}

function ResponsiveDoor() {
  const [width] = useWindowSize();

  return width > 600 ? <DoorWithMatrixRain /> : <DoorWithMatrixRainPh />;
}


export default App;
